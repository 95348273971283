import { APPROVED } from "utils/handleCheckingStatus";
import { NEW_DATA_PENDING } from "utils/handleCheckingStatus";
import { PENDING } from "utils/handleCheckingStatus";

export const roles = [
  {
    value: "Senior Technical Adviser (TB)",
    label: "Senior Technical Adviser (TB)",
  },
  {
    value: 'Programme Director',
    label: 'Programme Director',
  },
  { value: "Deputy Programme Director", label: "Deputy Programme Director" },
  { value: "Programme Manager_TB", label: "Programme Manager_TB" },
  { value: "Programme Manager_AIS", label: "Programme Manager_AIS" },
  {
    value: "MEAL and Digital Tool Manager",
    label: "MEAL and Digital Tool Manager",
  },
  {
    value: "MEAL And Digital Tool Officer",
    label: "MEAL And Digital Tool Officer",
  },
  { value: "MEAL Associate", label: "MEAL Associate" },
  { value: "Project Officer", label: "Project Officer" },
  { value: "Field Officer (Focal)", label: "Field Officer (Focal)" },
  { value: "Field Officer", label: "Field Officer" },
  { value: "Data Assistant", label: "Data Assistant" },

  // { value: 'Nurse Counsellor', label: 'Nurse Counsellor' },
  // { value: 'Developer', label: 'Developer' },
];

export const TBCodeRegistration = [
  { value: "Township", label: "Township" },
  { value: "Union", label: "Union" },
];
export const cigaTypeOptions = [
  { value: "Cigars", label: "Cigars" },
  { value: "Cigarette", label: "Cigarette" },
  { value: "E-Cigarette", label: "E-Cigarette" },
];
export const ethnicGroupOptions = [
  { value: "Kachin", label: "Kachin" },
  { value: "Kayah", label: "Kayah" },
  { value: "Karen", label: "Karen" },
  { value: "Chin", label: "Chin" },
  { value: "Mon", label: "Mon" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Rakhine", label: "Rakhine" },
  { value: "Shan", label: "Shan" },
  { value: "Others", label: "Others" },
];


export const signPersonTypeOptions = [
  { value: "Self", label: "Self" },
  { value: "Dad", label: "Dad" },
  { value: "Mom", label: "Mom" },
  { value: "Aunt", label: "Aunt" },
  { value: "Bro", label: "Brother" },
  { value: "Sis", label: "Sister" },
  { value: "Grandpa", label: "Grandpa" },
  { value: "Grandma", label: "Grandma" },
  { value: "Volunteer", label: "Volunteer" },
  { value: "Neighbour", label: "Neighbor" },
  { value: "Other", label: "Other" },
];

export const checkingstatusOptions = [
  { value: PENDING, label: "FOLLOW UP" },
  { value: NEW_DATA_PENDING, label: "NEW" },
  { value: APPROVED, label: "APPROVED" },
];

export const sex = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

export const typeOfTBDependingOnBACStatus = [
  { value: "BC", label: "BC" },
  { value: "CD", label: "CD" },
];

export const treatmentRegimen = [
  { value: "IR", label: "IR" },
  { value: "RR", label: "RR" },
  { value: "CR", label: "CR" },
  { value: "MR", label: "MR" },
];

export const yesOrNo = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
export const isTransferIn = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Transfer in", label: "Transfer in" },
];

export const timeAfterWakingToFirstCigarette = [
  { value: "<= 30 min", label: "<= 30 min" },
  { value: ">30 min", label: ">30 min" },
];

export const followUpOption = [
  { value: "Phone Call", label: "Phone Call" },
  { value: "In Person", label: "In Person" },
  { value: "Missing", label: "Missing" },
];

export const MISSING = "Missing";

export const missingDefaultRemark = "work stop"

export const statusOfSmokingForThisFollowUpToSkipForTIPatient = [
  { value: "S", label: "S" },
  { value: "R", label: "R" },
  { value: "Q", label: "Q" },
  { value: "D", label: "D" },
  { value: "L", label: "L" },
];

export const years = [
  { value: "2020", label: "2020" },
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
  { value: "2026", label: "2026" },
  { value: "2027", label: "2027" },
  { value: "2028", label: "2028" },
  { value: "2029", label: "2029" },
  { value: "2030", label: "2030" },
];

export const regimenTypes = [
  { value: "IR - Initial Regimen", label: "IR - Initial Regimen" },
  { value: "RR - Retreatment Regimen", label: "RR - Retreatment Regimen" },
  { value: "CR - Childhood Regimen", label: "CR - Childhood Regimen" },
  { value: "MR - Modified Regimen", label: "MR - Modified Regimen" },
];

export const educations = [
  { value: "Primary", label: "Primary" },
  { value: "Secondary", label: "Secondary" },
  { value: "Higher", label: "Higher" },
];

export const occuptions = [
  { value: "AMW", label: "AMW" },
  { value: "CHW", label: "CHW" },
  { value: "Other", label: "Other" },
];

export const phoneSupporteds = [
  { value: "Not Supported", label: "Not Supported" },
  { value: "AIS", label: "AIS" },
  { value: "GF", label: "GF" },
];

export const reportMonths = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
  // { value: "1 Quarter", label: "1st Quarter" },
  // { value: "2 Quarter", label: "2nd Quarter" },
  // { value: "3 Quarter", label: "3rd Quarter" },
  // { value: "4 Quarter", label: "4th Quarter" },
  { value: "Annual", label: "Annual" },
];

export const treatmentOutcomeOptions = [
  { value: "Cured", label: "Cured" },
  { value: "Completed", label: "Completed" },
  { value: "Died", label: "Died" },
  { value: "Lost to Follow up", label: "Lost to Follow up" },
  { value: "Failed", label: "Failed" },
  {
    value: "Move to Second Line Treatment",
    label: "Move to Second Line Treatment",
  },
  { value: "Not Evaluated", label: "Not Evaluated" },
];
export const outcomeMonths = [
  { value: "1 Quarter", label: "1st Quarter" },
  { value: "2 Quarter", label: "2nd Quarter" },
  { value: "3 Quarter", label: "3rd Quarter" },
  { value: "4 Quarter", label: "4th Quarter" },
  { value: "Annual", label: "Annual" },
];

export const finalOutcomes = [
  { value: "non-current-smoker", label: "non-current-smoker" },
  { value: "ongoing", label: "ongoing" },
  { value: "S", label: "S" },
  { value: "R", label: "R" },
  { value: "Q", label: "Q" },
  { value: "D", label: "D" },
  { value: "L", label: "L" },
  { value: "other", label: "other" },
  { value: "Missing", label: "Missing" },
];
export const finalOutcomeFilterOptions = [
  { value: "S", label: "S" },
  { value: "R", label: "R" },
  { value: "Q", label: "Q" },
  { value: "D", label: "D" },
  { value: "L", label: "L" },
];
